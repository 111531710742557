<template>
  <el-dialog
    :title="remark"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      ref="dataForm"
      :rules="dataRule"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="公司" prop="companyIds">
        <el-select v-model="dataForm.companyIds" multiple>
          <el-option
            v-for="(item, index) in companyList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getCompanyList } from '@/utils/options.js';
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      remark: '',
      dataForm: {
        jobId: '',
        companyIds: [],
      },
      companyList: [],
      dataRule: {
        companyIds: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
      },
    };
  },

  created() {
    this.getCompanyList();
  },

  methods: {
    init(jobId, remark) {
      this.dataForm.jobId = jobId || '';
      this.dataForm.companyIds = null;
      this.remark = remark;
      this.visible = true;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/sys/schedule/runCompany`,
            method: 'post',
            data: {
              jobIds: this.dataForm.jobId,
              companyIds: this.dataForm.companyIds.map((i) => i).join(','),
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
  },
};
</script>
